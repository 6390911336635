







































































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator"
import AccountBoxComponent from "@/components/AccountBoxComponent.vue"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import User from "@/models/User"
import Order from "@/models/Order"
import Product from "@/models/Product"
import CartModule from "@/store/CartModule"
import ProductService from "@/services/ProductService"
import OrderService from "@/services/OrderService"
import OrderDetail from "@/models/OrderDetail"
import SnackbarModule from "@/store/SnackbarModule"
import Address from "@/models/Address"
import AddressService from "@/services/AddressService"
import Client from "@/models/Client"
import ClientService from "@/services/ClientService"
import ClientDialogComponent from "@/components/ClientDialogComponent.vue"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import AddressDialog from "@/components/dialogs/AddressDialog.vue"
import LangModule from "@/store/LangModule"
import CartTool from "@/services/tool/CartTool"

@Component({components: {AccountBoxComponent, AddressDialog, ClientDialogComponent}})
export default class CheckoutView extends Vue {

    @Ref() readonly form!: HTMLFormElement

    lang: any = getModule(LangModule).lang
    sessionModule: SessionModule = getModule(SessionModule)
    user: User = this.sessionModule.session.user

    cartModule: CartModule = getModule(CartModule)

    get cart() {
        return this.cartModule.cart
    }

    get rateId() {
        return this.sessionModule.session.user.rateId
    }

    dialog: boolean = false
    addressDialog: boolean = false
    clientDialog: boolean = false
    search: string = ""
    order: Order = new Order()
    products: Product[] = []
    clients: Client[] = []
    newAddress: Address = new Address()
    loading: boolean = false
    shippingAddresses: Address[] = []

    /*    R U L E S   */
    minLength = 3
    maxLength = 25
    basicRules = [
        (input: string) => (input ? true : this.lang.emptyLabel),
        (input: string) => input?.length > this.minLength ? true : this.lang.minimumLength,
        (input: string) => input?.length < this.maxLength ? true : this.lang.maximusLength,
    ]
    clientRules = [(v: Client) => v ? true : this.lang.requiredInput]
    addressRules = [(v: Address) => v ? true : this.lang.requiredInput]

    headers = [
        {text: this.lang.products, align: 'center', value: 'product', width: '20%'},
        {text: this.lang.quantity, align: 'center', value: 'quantity', width: '20%'},
        {text: this.lang.commerciaRate, align: 'center', value: 'commercialRate', width: '20%'},
        {text: this.lang.taxBaseHeader, align: 'center', value: 'taxBase', width: '20%'},
    ]

    created() {
        if (this.cart.length == 0) {
            this.$router.push("/")
        } else {
            this.refresh()
        }
    }

    refresh() {
        this.user.commercialName
        ClientService.getMyClients(this, this.clients, 0, 10, "", true, this.sessionModule.session!.user!.id!)
        ProductService.getProductsById(this, this.cartModule.cart, this.products)
        this.watchClient()
    }

    getTotalAmount() {
        let amount = 0
        this.products.forEach(product => amount += product.price! * product.quantity)
        return amount
    }

    createOrder(send: boolean = false) {
        if (!(this.products.length > 0)) {
            getModule(SnackbarModule).makeToast(this.lang.noProductsInCart)
            return
        }
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.orderConfirmation, () => {
                this.order.orderDetails = []
                this.products.forEach(e => {
                    const orderDetail = new OrderDetail()
                    orderDetail.quantity = e.quantity
                    orderDetail.product = e
                    this.order.orderDetails!.push(orderDetail)
                });
                OrderService.postOrder(this, this.order, this.order.client!.id!, send)
                CartTool.removeAllProducts()
            }))
        } else {
            getModule(SnackbarModule).makeToast(this.lang.invalidOrderFields)
        }
    }

    getTotalTaxBase(): number {
        let totalTaxBase: number = 0
        this.products.forEach(product => {
            totalTaxBase += Number(product.commercialRate) * Number(product.quantity)
        })

        return Number(totalTaxBase.toFixed(2))
    }

    @Watch('order.client')
    watchClient() {
        if (this.order.client) {
            AddressService.getAddressByClient(this, this.shippingAddresses, this.order.client!.id!)
            this.order.shippingAddress = undefined
        }
    }
}
